import axios from "axios";
const { REACT_APP_API_ENDPOINT_TESTNET, REACT_APP_API_ENDPOINT_MAINNET } = process.env;
const providerEnv = localStorage.getItem('providerEnv') || "TestNet" 
const apiEndpointEnvFallback = "http://localhost:5000"
let endpointEnv
if(providerEnv === "TestNet") {
  endpointEnv = REACT_APP_API_ENDPOINT_TESTNET || apiEndpointEnvFallback
} else {
  endpointEnv = REACT_APP_API_ENDPOINT_MAINNET || apiEndpointEnvFallback
}
const plans = {
  dice: "44933ee784bcdda77b98ea83dfdf78cc",
  relay: "40e0dee6e310bbc535190611afcc6e6d",
};
// TODO convert to generic service
export default {
  getDices: async () => {
    const planId = plans.dice;
    return (await axios.get(`${endpointEnv}/api/v2/apps`, { params: { planId, deleted: false } }))?.data || 0;
  },
  createDice: async (data) => {
    const id = plans.dice;
    return (
      (await axios.post(`${endpointEnv}/api/v2/launch`, { id }))?.data || 0
    );
  },
  removeDice: async (id) => {
    return (await axios.delete(`${endpointEnv}/api/v2/apps/${id}`))?.data || 0;
  },
  createRelay: async (data) => {
    const id = plans.relay;
    return (
      (await axios.post(`${endpointEnv}/api/v2/launch`, { id }))?.data || 0
    );
  },
};
